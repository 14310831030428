import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/bruce.bentley/Projects/Personal/docz.brucebentley.dev/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Toggle } from './Toggle';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "toggle"
    }}>{`Toggle`}</h1>
    <p><em parentName="p">{`Toggle component description placeholder ...`}</em></p>
    <h3 {...{
      "id": "best-practices"
    }}>{`Best Practices`}</h3>
    <ul>
      <li parentName="ul"></li>
    </ul>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={Toggle} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic Usage`}</h2>
    <Toggle mdxType="Toggle"></Toggle>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      